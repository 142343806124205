export default Object.freeze({
  LOGIN: "/login",
  SIGNUP: "/sign_up",
  FORGOTPASSWORD: "/forgot_password",
  PHONE_UPDATE: "/phone_update",
  PHONEVERIFICATIONCODE: "/phone_verification_code",
  FORGOTPASSWORDVERIFICATIONCODE: "/forgot_password_verification_code",

  USERPROFILE: "/user_profile",
  CREDIT: "/credits",
  TOPUPBILLPAYMENTCHECK: "/top_up_bill_check",
  TOPUPBILLPAYMENTFAILED: "/top_up_bill_failed",
  TOPUPBILLPAYMENTSUCCESS: "/top_up_bill_success",

  LANDING: "/",
  HOME: "/home",
  EXPLORE: "/explore",
  BUSINESS: "/business/:slug",
  HOURLYBOOKINGSUMMARY: "/hourly_booking_summary",
  HOURLYBOOKINGPAYMENTCHECK: "/hourly_booking_bill_check",
  HOURLYBOOKINGPAYMENTFAILED: "/hourly_booking_bill_failed",
  HOURLYBOOKINGPAYMENTSUCCESS: "/hourly_booking_bill_success",
  SUBSCRIPTIONPLANSUMMARY: "/subscription_plan_summary",
  SUBSCRIPTIONPLANPAYMENTCHECK: "/subscription_bill_check",
  SUBSCRIPTIONPLANPAYMENTFAILED: "/subscription_plan_failed",
  SUBSCRIPTIONPLANPAYMENTSUCCESS: "/subscription_plan_success",
  PACKAGEPLANSUMMARY: "/package_plan_summary",
  PACKAGEPAYMENTCHECK: "/package_bill_check",
  PACKAGEPAYMENTFAILED: "/package_bill_failed",
  PACKAGEPAYMENTSUCCESS: "/package_bill_success",

  APPOINTMENTPAYMENTSUCCESS: "/appointment_bill_success",
  APPOINTMENTSUMMARY: "/appointment_summary",

  FITNESSCLASS: "/fitness_class",
  FITNESSCLASSSESSIONBOOKINGSUMMARY: "/fitness_class_session_booking_summary",
  FITNESSCLASSSESSIONBOOKINGPAYMENTCHECK: "/fitness_class_session_booking_bill_check",
  FITNESSCLASSSESSIONBOOKINGPAYMENTFAILED: "/fitness_class_session_booking_bill_failed",
  FITNESSCLASSSESSIONBOOKINGPAYMENTSUCCESS: "/fitness_class_session_booking_bill_success",
  FITNESSCLASSSESSIONBOOKINGREQUESTSUMMARY: "/fitness_class_session_booking_request_summary",

  EVENT: "/event",
  EVENTBOOKINGSUMMARY: "/event_booking_summary",
  EVENTBOOKINGPAYMENTCHECK: "/event_booking_bill_check",
  EVENTBOOKINGPAYMENTFAILED: "/event_booking_bill_failed",
  EVENTBOOKINGPAYMENTSUCCESS: "/event_booking_bill_success",

  PURCHASES: "/purchases",
  PAYMENT_METHODS: "/payment_methods",
  REFERRALS: "/referrals",
  PRIVACYPOLICY: "/privacy_policy",
  CONTACT: "/contact",
  TERMS: "/terms",

  MEMBERSHIP_PLAN_SUMMARY: "/membership_plan_summary",
  MEMBERSHIP_PLAN_PAYMENT_CHECK: "/membership_bill_check",
  MEMBERSHIP_PLAN_PAYMENT_FAILED: "/membership_plan_failed",
  MEMBERSHIP_PLAN_PAYMENT_SUCCESS: "/membership_plan_success",

  MEMBERSHIPS: "/memberships",
});
