import PlaceIcon from "@mui/icons-material/Place";
import StarIcon from "@mui/icons-material/Star";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function BusinessCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <Grid
      onClick={() => navigate({ pathname: routes.BUSINESS.replace(":slug", itemDetails.slug) })}
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .businessTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={1}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex" xs={4}>
            <img
              src={itemDetails.images[0].imagePath}
              alt="businessImage"
              width="100%"
              height={isMobile ? 100 : 100}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
            xs={8}
          >
            <Grid item>
              <Grid item>
                <Typography fontWeight="bold" className="businessTitle">
                  {itemDetails.name}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography>{itemDetails.city}</Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignContent="center">
              <StarIcon sx={{ color: theme.palette.colors.brand.primary }} />
              <Spacer position="left" />
              <Typography>{itemDetails.reviews.averageRatings}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

BusinessCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    name: "",
    slug: "",
    city: "",
    reviews: PropTypes.shape({
      averageRatings: "",
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: "",
      }),
    ),
  }),
};

BusinessCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    slug: PropTypes.string,
    city: PropTypes.string,
    reviews: PropTypes.shape({
      averageRatings: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
      }),
    ),
  }),
};

export default BusinessCard;
