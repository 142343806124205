import React from "react";
import PropTypes from "prop-types";
import { Box, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useFormikContext } from "formik";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

function PhoneNumberTextField({ name, width }) {
  const theme = useTheme();
  const { setFieldValue, errors, setFieldTouched, touched, values } = useFormikContext();
  const showError = touched[name] && typeof errors[name] === "string";

  return (
    <Box>
      <PhoneInput
        specialLabel=""
        value={values[name]}
        country="my"
        onlyCountries={[
          "cn", // China
          "vn", // Vietnam
          "mm", // Myanmar
          "bn", // Brunei
          "my", // Malaysia
          "ph", // Philippines
          "sg", // Singapore
          "kh", // Cambodia
          "hk", // Hong Kong
          "la", // Laos PDR
          "th", // Thailand
          "au", // Australia
          "nz", // New Zealand
          "id", // Indonesia
          "us", // United States
          "gb", // United Kingdom
          "in", // India
          "bd", // Bangladesh
        ]}
        onBlur={() => setFieldTouched(name)}
        onChange={(e) => setFieldValue(name, `+${e}`)}
        inputStyle={{
          width,
          borderRadius: theme.shape.borderRadius[1],
          borderColor: showError && theme.palette.colors.ui.error,
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      />
      {showError && (
        <FormHelperText
          sx={{
            color: theme.palette.colors.text.error,
            margin: "3px 14px 0px 14px",
          }}
        >
          {errors[name]}
        </FormHelperText>
      )}
    </Box>
  );
}

PhoneNumberTextField.defaultProps = {
  width: "100%",
};

PhoneNumberTextField.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default PhoneNumberTextField;
