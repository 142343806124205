import { Box, Grid, Modal, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../components/button/custom-button.component";
import FormFieldTextWithIcon from "../../../components/forms/form-field-text-with-icon.component";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import { validateExclusiveReferralCode } from "../../../services/exclusive-referral/exclusive-referral-slice.service";

const ModalBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isMobile",
})(({ theme, isMobile }) => ({
  position: "absolute",
  top: !isMobile && "50%",
  left: !isMobile && "50%",
  transform: !isMobile && "translate(-50%, -50%)",
  backgroundColor: theme.palette.colors.bg.white,
  borderRadius: !isMobile && theme.shape.borderRadius[2],
  boxShadow: 24,
  width: isMobile ? "100%" : "450px",
  height: isMobile ? "100%" : "320px",
  outline: "none",
  padding: "24px",
}));

export function ExclusiveReferralModal({ showModal, setShowModal, setShowCodeInputModal }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Modal open={showModal} onClose={() => setShowModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Box
          sx={{
            overflowY: !isMobile && "auto",
          }}
        >
          <TopTitleBox
            title="This facility is only for exclusive members"
            requireCloseButton={true}
            setShowModal={setShowModal}
          />
          <Spacer position="top" size="l" />
          <Grid container item xs={12} textAlign="center">
            We will require one more verification before we can proceed
          </Grid>
          <Grid container item xs={12} paddingTop="50px" justifyContent="center">
            <CustomButton
              fontSize={theme.fonts.fontSizes.size16}
              paddingTop="20px"
              width="60%"
              onClick={() => {
                setShowModal(false);
                setShowCodeInputModal(true);
              }}
            >
              Proceed
            </CustomButton>
          </Grid>
        </Box>
      </ModalBox>
    </Modal>
  );
}

ExclusiveReferralModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setShowCodeInputModal: PropTypes.func.isRequired,
};

export function ExclusiveReferralCodeInputModal({
  showCodeInputModal,
  setShowCodeInputModal,
  setShowSessionModal,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const { getBusinessDetailsObj } = useSelector(businessSelector);

  const handleSubmitCode = (values, { setErrors }) => {
    const validationFormData = {
      businessId: getBusinessDetailsObj.data.id,
      exclusiveReferralCode: values.exclusiveReferralCode ?? null,
    };
    dispatch(validateExclusiveReferralCode({ ...validationFormData })).then(({ meta, error }) => {
      setIsLoading(true);
      if (meta.requestStatus === "fulfilled") {
        setIsLoading(false);
        setShowCodeInputModal(false);
        setShowSessionModal(true);
      }
      if (meta.requestStatus === "rejected") {
        setIsLoading(false);
        setErrors({ exclusiveReferralCode: error.message || "An error occurred" });
      }
    });
  };

  return (
    <Modal open={showCodeInputModal} onClose={() => setShowCodeInputModal(false)}>
      <ModalBox isMobile={isMobile}>
        <Box
          sx={{
            overflowY: !isMobile && "auto",
          }}
        >
          <TopTitleBox
            title="Exclusive Referral Invite"
            requireCloseButton={true}
            setShowModal={setShowCodeInputModal}
          />
          <Spacer position="top" size="l" />
          <Grid container item xs={12} justifyContent="center">
            Please enter the exclusive referral code:
          </Grid>
          <Grid container item paddingTop="10px">
            <Form
              initialValues={{
                exclusiveReferralCode: "",
              }}
              validationSchema={Yup.object().shape({
                exclusiveReferralCode: Yup.string().required().label("Exclusive Referral Code"),
              })}
              onSubmit={handleSubmitCode}
            >
              <Grid item xs={12}>
                <FormFieldTextWithIcon
                  name="exclusiveReferralCode"
                  placeholder="Enter exclusive referral code"
                  toUpper={true}
                />
              </Grid>

              <Grid item textAlign="center" xs={12} paddingTop="50px">
                <FormSubmitButton isLoading={isLoading} width="60%" borderRadius={3}>
                  <Typography>Validate</Typography>
                </FormSubmitButton>
              </Grid>
            </Form>
          </Grid>
        </Box>
      </ModalBox>
    </Modal>
  );
}

ExclusiveReferralCodeInputModal.propTypes = {
  showCodeInputModal: PropTypes.bool.isRequired,
  setShowCodeInputModal: PropTypes.func.isRequired,
  setShowSessionModal: PropTypes.func.isRequired,
};
