import PlaceIcon from "@mui/icons-material/Place";
import StarIcon from "@mui/icons-material/Star";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "../../../../components/navigation/routes";
import PaddedView from "../../../../components/utils/padded-view.component";
import Spacer from "../../../../components/utils/spacer.component";

function BusinessResultCard({ itemDetails }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Grid
      onClick={() => navigate({ pathname: routes.BUSINESS.replace(":slug", itemDetails.slug) })}
      item
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        borderRadius: `${theme.shape.borderRadius[2]}px`,
        objectFit: "cover",
        cursor: "pointer",
        "&:hover .businessTitle": { color: theme.palette.colors.brand.primary },
      }}
    >
      <PaddedView multiples={isMobile ? 1 : 2}>
        <Grid container columnSpacing={2} alignContent="center">
          <Grid item alignSelf="center" paddingY="5px" alignItems="center" display="flex">
            <img
              src={itemDetails.images[0].imagePath}
              alt="businessImage"
              width={isMobile ? 100 : 200}
              height={isMobile ? 100 : 150}
              style={{ borderRadius: `${theme.shape.borderRadius[2]}px`, objectFit: "cover" }}
            />
          </Grid>
          <Grid
            item
            display="grid"
            flexDirection="column"
            alignContent="space-between"
            marginY="10px"
          >
            <Grid item>
              <Grid item>
                {itemDetails.exclusiveReferrals && (
                  <Typography
                    color="primary"
                    className="exclusiveTag"
                    sx={{
                      fontSize: "14px",
                      width: "fit-content",
                      border: "1px solid",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginBottom: "6px",
                    }}
                  >
                    Exclusive
                  </Typography>
                )}
                {itemDetails.requireMembership && (
                  <Typography
                    color="primary"
                    className="exclusiveTag"
                    sx={{
                      fontSize: "14px",
                      width: "fit-content",
                      border: "1px solid",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      marginBottom: "6px",
                    }}
                  >
                    Members Only
                  </Typography>
                )}
                <Typography
                  fontWeight="bold"
                  className="businessTitle"
                  maxWidth={width < 450 ? "180px" : "none"}
                  sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                >
                  {itemDetails.name}
                </Typography>
              </Grid>
              <Spacer />
              <Grid item display="flex" alignContent="center">
                <PlaceIcon color="primary" />
                <Spacer position="left" />
                <Typography>
                  {itemDetails.city} - {itemDetails.distanceKM}KM
                </Typography>
              </Grid>
            </Grid>
            <Grid item display="flex" alignContent="center">
              <StarIcon sx={{ color: theme.palette.colors.brand.primary }} />
              <Spacer position="left" />
              <Typography>{itemDetails.reviews.averageRatings}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </PaddedView>
    </Grid>
  );
}

BusinessResultCard.defaultProps = {
  itemDetails: PropTypes.shape({
    id: null,
    name: "",
    slug: "",
    city: "",
    exclusiveReferrals: false,
    requireMembership: false,
    reviews: PropTypes.shape({
      averageRatings: "",
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: "",
      }),
    ),
  }),
};

BusinessResultCard.propTypes = {
  itemDetails: PropTypes.shape({
    id: PropTypes.number,
    distanceKM: PropTypes.string,
    exclusiveReferrals: PropTypes.bool,
    requireMembership: PropTypes.bool,
    name: PropTypes.string,
    slug: PropTypes.string,
    city: PropTypes.string,
    reviews: PropTypes.shape({
      averageRatings: PropTypes.string,
    }),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        imagePath: PropTypes.string,
      }),
    ),
  }),
};

export default BusinessResultCard;
