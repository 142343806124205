import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import KatchLogo from "../../../assets/images/katch_logo";
import FormSubmitButton from "../../../components/forms/form-submit-button.component";
import Form from "../../../components/forms/form.component";
import PhoneNumberTextField from "../../../components/forms/phone-number-text-field.component";
import routes from "../../../components/navigation/routes";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import PaddedView from "../../../components/utils/padded-view.component";
import Spacer from "../../../components/utils/spacer.component";
import TopTitleBox from "../../../components/utils/top-title-box.component";
import {
  profileSelector,
  updatePhoneNumber,
} from "../../../services/profile/profile-slice.service";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .when({
      is: (value) => value?.length,
      then: (rule) => rule.min(10),
    })
    .required()
    .label("Phone Number"),
});

function UpdatePhoneNumberScreen() {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const createSnackBar = useContext(SnackbarContext);
  const { updatePhoneNumberObj } = useSelector(profileSelector);

  const onSubmit = (value) => {
    dispatch(updatePhoneNumber(value)).then(({ meta, error, payload }) => {
      if (meta.requestStatus === "fulfilled") {
        createSnackBar({
          message: payload.message,
          type: "success",
          open: true,
        });
        navigate(routes.PHONEVERIFICATIONCODE, {
          state: { method: "phone", identifier: payload.data.phoneNumber },
        });
      }
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.colors.bg.primary,
        minHeight: "100vh",
        opacity: 1,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          flexDirection: "column",
        }}
      >
        <PaddedView multiples={isMobile ? 0 : 4}>
          <Grid item>
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Grid item xs={12} sx={{ height: isMobile ? 45 : 60, textAlign: "center" }}>
                <KatchLogo color="green" />
              </Grid>
              <Spacer size="m" />
              <Box>
                <Box
                  sx={{
                    backgroundColor: theme.palette.colors.bg.white,
                    borderRadius: `${theme.shape.borderRadius[2]}px`,
                    flex: 1,
                    maxWidth: 500,
                    minWidth: isMobile ? null : 450,
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: 2,
                    overflow: "hidden",
                  }}
                >
                  <TopTitleBox title="Update Phone Number" requireCloseButton={false} />

                  <PaddedView multiples={isMobile ? 2 : 4}>
                    <Form
                      initialValues={{ phoneNumber: location.state?.phone }}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      <Grid item xs={12}>
                        <PhoneNumberTextField name="phoneNumber" />
                      </Grid>
                      <Spacer size="xl" />
                      <Grid item xs={12} sx={{ textAlign: "center" }}>
                        <FormSubmitButton
                          isLoading={updatePhoneNumberObj.status === "pending"}
                          width="60%"
                          borderRadius={1}
                        >
                          <Typography>Submit</Typography>
                        </FormSubmitButton>
                      </Grid>
                    </Form>
                  </PaddedView>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </PaddedView>
      </Grid>
    </Box>
  );
}

export default UpdatePhoneNumberScreen;
