import {
  Divider,
  Grid,
  LinearProgress,
  Pagination,
  Rating,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../../components/button/custom-button.component";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import HorizontalDivider from "../../../components/utils/horizontal-divider.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import { getReviews, reviewSelector } from "../../../services/review/review-slice.service";
import ReviewLoader from "../loader/review-loader.component";
import ReviewRow from "./review-row.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function ReviewSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const { getReviewsObj } = useSelector(reviewSelector);
  const { getBusinessDetailsObj } = useSelector(businessSelector);
  const [page, setPage] = useState(1);
  const createSnackBar = useContext(SnackbarContext);
  const [loadMore, setLoadMore] = useState(5);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
    const urlParams = `?reviewable_type=merchant_business&reviewable_id=${getBusinessDetailsObj.data.id}&page=${newPage}`;
    dispatch(getReviews(urlParams)).then(({ meta, error }) => {
      if (meta.requestStatus === "rejected") {
        createSnackBar({
          message: error.message,
          type: "error",
          open: true,
        });
      }
    });
  };

  useEffect(() => {
    onPageChange(null, 1);
  }, []);

  const renderReviews = () => {
    if (getReviewsObj.status === "succeeded") {
      if (getReviewsObj.data.items.length > 0) {
        return (
          <>
            {getReviewsObj.data.items.slice(0, loadMore).map((item) => (
              <ReviewRow itemDetails={item} key={item.id} />
            ))}
            <Grid container justifyContent="center">
              {loadMore === undefined || getReviewsObj.data.items.length < 6 ? (
                <Pagination
                  page={page}
                  onChange={onPageChange}
                  count={getReviewsObj.data.pagination.totalPages}
                  variant="outlined"
                />
              ) : (
                <Grid item xs={4}>
                  <CustomButton
                    variant="text"
                    fontSize={theme.fonts.fontSizes.size16}
                    onClick={() => setLoadMore(undefined)}
                  >
                    Load More
                  </CustomButton>
                </Grid>
              )}
            </Grid>
          </>
        );
      }

      return (
        <Grid container display="flex" justifyContent="center">
          <Typography>No Reviews.</Typography>
        </Grid>
      );
    }

    return (
      <>
        <ReviewLoader />
        <ReviewLoader />
      </>
    );
  };

  const renderRatingComposition = () => {
    const ratingCompositions = [];
    for (let i = 5; i >= 1; i -= 1) {
      const item = (
        <Grid item xs={12} container key={uuidv4()} alignItems="center" columnSpacing={1}>
          <Grid item xs={3}>
            <Typography textAlign="center">{i} stars</Typography>
          </Grid>
          <Grid item xs={6}>
            <LinearProgress
              sx={{ height: 10 }}
              variant="determinate"
              value={getBusinessDetailsObj.data.reviews.ratingComposition[i]}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>{getBusinessDetailsObj.data.reviews.ratingComposition[i]}%</Typography>
          </Grid>
        </Grid>
      );
      ratingCompositions.push(item);
    }

    return ratingCompositions;
  };

  return (
    <Grid item xs={12}>
      <SectionText>Reviews</SectionText>
      <Spacer size="s" />
      <Grid container alignItems="center">
        <Grid item xs={isMobile ? 12 : 2}>
          <Typography>Total Reviews</Typography>
          <Typography fontSize={25} fontWeight="bold">
            {getBusinessDetailsObj.data.reviews.totalRatings}
          </Typography>
        </Grid>

        {isMobile ? (
          <HorizontalDivider spacerSize="l" />
        ) : (
          <Grid item xs={2} container justifyContent="center" height={100}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
        )}

        <Grid item xs={isMobile ? 12 : 2}>
          <Typography>Average Rating</Typography>
          <Grid container alignItems="center">
            <Typography fontSize={25} fontWeight="bold">
              {getBusinessDetailsObj.data.reviews.averageRatings}
            </Typography>
            <Spacer position="left" />
            <Rating
              precision={0.1}
              value={parseFloat(getBusinessDetailsObj.data.reviews.averageRatings)}
              readOnly
              sx={{ color: theme.palette.colors.brand.primary }}
            />
          </Grid>
        </Grid>
        {isMobile ? (
          <HorizontalDivider spacerSize="l" />
        ) : (
          <Grid item xs={2} container justifyContent="center" height={100}>
            <Divider orientation="vertical" variant="middle" />
          </Grid>
        )}
        <Grid item xs={isMobile ? 12 : 4}>
          {renderRatingComposition()}
        </Grid>
      </Grid>
      <HorizontalDivider spacerSize="l" />
      <Grid item xs={12}>
        {renderReviews()}
      </Grid>
    </Grid>
  );
}

export default ReviewSection;
