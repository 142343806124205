import { Box, Grid, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackbarContext } from "../../../components/notification/snackbar.context";
import ItemCarousel from "../../../components/utils/item-carousel.component";
import CustomSkeleton from "../../../components/utils/skeleton.component";
import Spacer from "../../../components/utils/spacer.component";
import { businessSelector } from "../../../services/business/business-slice.service";
import {
  getMembershipPlans,
  membershipPlanSelector,
} from "../../../services/membership/plan/membership-plan-slice.service";
import MembershipPlanCard from "./membership-plan-card.component";

const SectionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.fonts.fontSizes.size18,
  fontWeight: "bold",
}));

function MembershipSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getMembershipPlansObj } = useSelector(membershipPlanSelector);
  const dispatch = useDispatch();
  const createSnackBar = useContext(SnackbarContext);
  const { getBusinessDetailsObj } = useSelector(businessSelector);

  const getBusinessMembershipPlans = () => {
    dispatch(getMembershipPlans({ businessId: getBusinessDetailsObj.data.id })).then(
      ({ meta, error }) => {
        if (meta.requestStatus === "rejected") {
          createSnackBar({
            message: error.message,
            type: "error",
            open: true,
          });
        }
      },
    );
  };

  useEffect(() => {
    getBusinessMembershipPlans();
  }, []);

  const renderLoader = () => {
    const loaders = [];
    for (let i = 0; i < 3; i += 1) {
      loaders.push(
        <Grid item xs={isMobile ? 12 : 4} key={i} position="relative">
          <CustomSkeleton width="100%" height="135px" />
        </Grid>,
      );
    }
    return loaders;
  };

  return (
    <Grid item xs={12}>
      <Grid container display="flex" justifyContent="space-between">
        <Grid item display="flex" alignItems="center">
          <SectionText>Membership Plan</SectionText>
        </Grid>
      </Grid>
      <Spacer size="m" />
      <Grid item xs={12} justifyContent="center">
        {getMembershipPlansObj.status === "succeeded" && getMembershipPlansObj.data && (
          <ItemCarousel slidesToScroll={3} slidesToShow={3}>
            <Box>
              <MembershipPlanCard itemDetails={getMembershipPlansObj.data} />
            </Box>
          </ItemCarousel>
        )}
        {getMembershipPlansObj.status === "succeeded" && !getMembershipPlansObj.data && (
          <Grid container display="flex" justifyContent="center">
            No memberships.
          </Grid>
        )}
        {getMembershipPlansObj.status === "pending" && (
          <Grid container item columnSpacing={1} rowSpacing={1.5}>
            {renderLoader()}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default MembershipSection;
